/* eslint-disable import/no-unused-modules */
// @flow

import React, { Suspense, useEffect } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { type GraphQLTaggedNode } from 'relay-runtime'

import TinyLoader from 'react-ui/components/LoadingSpinner/TinyLoader'
import useMultiSelect from 'platform_web/pages/Staff/hooks/useMultiSelect'
import RemoveRolesMutation from 'platform_web/pages/Staff/mutations/RemoveRolesMutation'
import ResendRolesMutation from 'platform_web/pages/Staff/mutations/ResendRolesMutation'
import { Button, FlexContainer } from 'care-ui'

import useActionModal from '../../../hooks/useActionModal'
import useIndividualActions from '../../hooks/useIndividualActions'
import useIndividualTable from '../../hooks/useIndividualTable'
import IndividualsListTable from '../../IndividualsListTable'
import type { IndividualsPageUser } from '../../IndividualsPage'
import IndividualsTableControls from '../../IndividualsTableControls'
import RequestAssessmentsMutation from '../../mutations/AssessmentRequestsBulkCreate'
import ClinicianAssignmentsCreateMutation from '../../mutations/ClinicianAssignmentsCreateMutation'
import DischargeRolesMutation from '../../mutations/DischargeRolesMutation'
import ReinviteRolesMutation from '../../mutations/ReinviteRolesMutation'
import AssignCliniciansModal from '../AssignCliniciansModal'
import DischargeModal from '../DischargeModal'
import ReinviteModal from '../ReinviteModal'
import RemoveIndividualsModal from '../RemoveIndividualsModal'
import RequestAssessmentsModal from '../RequestAssessmentsModal'
import ResendInvitationsModal from '../ResendInvitationsModal'

import type { ReadOnlyArrayElement } from 'services/flow'
import type {
  ClinicianIndividualAssignmentStatusEnum,
  IndividualsTable_user,
} from '../../query/__generated__/IndividualsTable_user.graphql'

type ActionButtonType = {
  action: string,
  disabled: boolean,
  label: string,
  modalComponent: any,
  mutationQuery: GraphQLTaggedNode,
}
type PropsType = {
  user: IndividualsPageUser,
}

type IndividualsConnectionType = $PropertyType<
  IndividualsTable_user,
  'individuals',
>

type ClinicianAssignmentsType = $NonMaybeType<
  $PropertyType<IndividualsConnectionType, 'edges'>,
>

export type IndividualsPropsType<T> = {
  data: ClinicianAssignmentsType,
  important_formulas: $ReadOnlyArray<any>,
  queryState: T,
}

export type IndividualType = $PropertyType<
  $NonMaybeType<ReadOnlyArrayElement<ClinicianAssignmentsType>>,
  'node',
>

export type ClinicianIndividualAssignmentStatusEnumType = ClinicianIndividualAssignmentStatusEnum

// Temporary actions has been disabled for incomplete ones and Discharge roles and mutation has been added as placeholder
type actionButtonGroupsType = {
  translation: TFunction,
}

const buildActionButtonGroups = (
  params: actionButtonGroupsType,
): ActionButtonType[] => {
  const { translation } = params

  return [
    {
      action: 'requestAssessments',
      label: translation('request_assessments'),
      disabled: false,
      modalComponent: RequestAssessmentsModal,
      mutationQuery: RequestAssessmentsMutation,
    },
    {
      action: 'assignClinicians',
      label: translation('assign_clinicians'),
      disabled: false,
      modalComponent: AssignCliniciansModal,
      mutationQuery: ClinicianAssignmentsCreateMutation,
    },
    {
      action: 'resendInvitations',
      label: translation('resend_invitations'),
      disabled: false,
      modalComponent: ResendInvitationsModal,
      mutationQuery: ResendRolesMutation,
    },
    {
      action: 'discharge',
      label: translation('discharge'),
      disabled: false,
      modalComponent: DischargeModal,
      mutationQuery: DischargeRolesMutation,
    },
    {
      action: 'remove',
      label: translation('remove'),
      disabled: false,
      modalComponent: RemoveIndividualsModal,
      mutationQuery: RemoveRolesMutation,
    },
    {
      action: 'reinvite',
      label: translation('reinvite'),
      disabled: false,
      modalComponent: ReinviteModal,
      mutationQuery: ReinviteRolesMutation,
    },
  ]
}

export const IndividualsTable = (props: PropsType) => {
  const { user: currentUser } = props
  const useIndividualTablePayload = useIndividualTable({ currentUser })
  const { tableType, queryVariables } = useIndividualTablePayload
  const multiSelectPayload = useMultiSelect()

  const { t: translation } = useTranslation('staff')

  const {
    allSelected,
    hasSelectedRoleIds,
    setMultiSelectState,
  } = multiSelectPayload

  const { actions } = useIndividualActions({ tableType })

  // Feature Toggle
  const enableMultiSelect = window.GLOBALS?.features?.MULTI_SELECT

  const actionButtonGroups = buildActionButtonGroups({ translation })

  const {
    ModalComponent,
    handleButtonClick,
    handleCloseModal,
    isOpen,
    actionButton,
  } = useActionModal({ actionButtonGroups })

  useEffect(
    () => {
      setMultiSelectState({
        isMultiSelect: enableMultiSelect && !!actions.length,
      })
    },
    [tableType],
  )

  return (
    <>
      {/* Table Controls */}
      {!hasSelectedRoleIds &&
        !allSelected && (
          <IndividualsTableControls
            useIndividualTablePayload={useIndividualTablePayload}
            multiSelectPayload={multiSelectPayload}
            actions={actions}
          />
        )}
      {ModalComponent && (
        <ModalComponent
          closeModal={handleCloseModal}
          isOpen={isOpen}
          multiSelectPayload={multiSelectPayload}
          mutationQuery={actionButton?.mutationQuery}
          queryVariables={queryVariables}
        />
      )}

      {/* MultiSelect Actions */}
      {(hasSelectedRoleIds || allSelected) && (
        <FlexContainer paddingX="xxs" gap="xxxs">
          {actionButtonGroups.map(
            ({ action, label, disabled }) =>
              actions.includes(action) && (
                <Button
                  onClick={
                    !disabled ? () => handleButtonClick(action) : undefined
                  }
                  key={action}
                  variant="primary"
                >
                  {label}
                </Button>
              ),
          )}
        </FlexContainer>
      )}

      {/* Table Component */}
      <Suspense fallback={<TinyLoader message={translation('loading')} />}>
        <IndividualsListTable
          useIndividualTablePayload={useIndividualTablePayload}
          multiSelectPayload={multiSelectPayload}
        />
      </Suspense>
    </>
  )
}
